@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.subheading {
  @include mix.text(32, 700, 32);
  text-align: center;

  @include mix.vp-1024 {
    @include mix.text(20, 700, 20);
    height: 80px;
    min-height: 80px;
    max-width: 400px;
  }
}
