@use 'src/styles/mixins' as mix;
@use 'src/styles/variables' as var;

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  max-width: 400px;
  width: 100%;

  @include mix.vp-767 {
    padding: 0 16px;
    gap: 40px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
