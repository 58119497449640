@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.skeleton {
  width: 100%;
  height: 24px;
  @include mix.background-gradient;
  animation: loading 1s infinite linear;
}

@keyframes loading {
  to {
    background-position: -200% 0;
  }
}
