@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.main {
  @include mix.about-block;

  @include mix.vp-1024 {
    justify-content: space-between;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.image {
  width: 200px;
  height: 200px;
  background-color: var.$gray;
  border-radius: 10px;
  min-width: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mix.vp-767 {
    width: 140px;
    height: 140px;
    min-width: 140px;
  }
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.text {
  padding: 16px;
  background-color: var.$gray;
  border-radius: 10px;
}

.top {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.toggles {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.bottom {
  display: flex;
  gap: 20px;
}

.input {
  width: 100%;
  background-color: var.$gray;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
