@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  flex-direction: column;
}

.area {
  display: flex;
  align-items: center;
  gap: 20px;

  @include mix.vp-767 {
    flex-direction: column;
    gap: 0;
  }
}

.slider {
  width: 100%;
}

.inputFile {
  width: 150px;
  min-width: 150px;

  @include mix.vp-767 {
    width: 100%;
    min-width: 100%;
  }
}

.docWrapper {
  padding: 16px 0;

  @include mix.vp-767 {
    padding: 10px 0 0;
  }
}

.docLoader {
  padding: 16px 0;

  @include mix.vp-767 {
    width: 100%;
    padding: 0 0 10px;
  }
}
