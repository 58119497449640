@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  width: 100%;
  border-bottom: 1px solid var.$black;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  position: sticky;
  top: 0;
  z-index: 100;
  transition: all 0.3s;
  display: none;
  background-color: rgba(var.$gray, 0.8);
  backdrop-filter: blur(5px);

  @include mix.vp-1024 {
    display: flex;
  }
}

.button {
  width: 120px;
}
