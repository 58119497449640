@use 'src/styles/mixins' as mix;
@use 'src/styles/variables' as var;

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  max-width: 400px;
  width: 100%;

  @include mix.vp-767 {
    padding: 0 16px;
    gap: 40px;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.error {
  color: var.$danger;
  @include mix.text(16, 400, 16);
  text-align: center;
  margin-bottom: 8px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.other {
  text-align: center;
  @include mix.text(16, 500, 16);
}
