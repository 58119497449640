@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input {
  width: 100%;
  resize: none;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  @include mix.text(36, 800, 40);
  height: 40px;
  overflow: hidden;

  &::placeholder {
    color: var.$gray-dark;
  }

  &.disabled {
    color: var.$gray-dark;
    cursor: not-allowed;
  }
}
