@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  justify-content: space-between;

  @include mix.vp-1024 {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;

  @include mix.vp-1024 {
    width: 100%;
  }
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;

  @include mix.vp-1024 {
    width: 100%;
  }
}

.rows {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.actions {
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 200px;
  padding: 12px 4px;

  @include mix.vp-1024 {
    padding: 16px;
    gap: 20px;
  }
}

.actionButton {
  @include mix.vp-1024 {
    display: none;
  }
}

.actionButtonMobile {
  display: none;

  @include mix.vp-1024 {
    display: block;
    width: 100%;
  }
}
