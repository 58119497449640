@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon {
  width: 36px;
  height: 36px;
  background-color: var.$black;
  padding: 4px;
  border-radius: 10px;
}

.text {
  @include mix.text(36, 800, 40);
}
