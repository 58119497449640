@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.gradient {
  overflow: hidden;
  border-radius: 100%;
  filter: blur(40px);
  animation: move 20s ease-in-out infinite;

  @include mix.vp-1024 {
    filter: blur(20px);
  }

  &::before {
    content: '';
    position: absolute;
    inset: -50%;
    background: radial-gradient(
      circle at center,
      #00d4ffcc 0%,
      #090979cc 45%,
      #020024cc 100%
    );
    animation: rotate 15s linear infinite;
  }

  &::after {
    content: '';
    position: absolute;
    inset: -50%;
    background: radial-gradient(
      circle at center,
      #00d4ff66 20%,
      #09097966 60%,
      #02002466 100%
    );
    animation: rotate 12s linear infinite reverse;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes move {
  0% {
    border-radius: 62% 38% 65% 35% / 58% 48% 52% 42%;
    transform: translate(0, 0) rotate(0deg);
  }
  33% {
    border-radius: 45% 55% 52% 48% / 45% 70% 30% 55%;
    transform: translate(5px, 5px) rotate(110deg);
  }
  66% {
    border-radius: 55% 45% 40% 60% / 70% 35% 65% 30%;
    transform: translate(-5px, -5px) rotate(240deg);
  }
  100% {
    border-radius: 62% 38% 65% 35% / 58% 48% 52% 42%;
    transform: translate(0, 0) rotate(360deg);
  }
}
