@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.row {
  display: flex;
  align-items: center;
  gap: 8px;

  &Value {
    @include mix.text(20, 400, 24);

    @include mix.vp-1024 {
      @include mix.text(18, 400, 20);
    }
  }

  &Title {
    @include mix.text(20, 800, 24);

    @include mix.vp-1024 {
      @include mix.text(18, 800, 20);
    }
  }
}
