@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.main {
  @include mix.about-block;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
}

.charts {
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 100%;
}

.pieChart {
  position: relative;
  height: 150px;
  min-width: 150px;
  border-radius: 50%;
  background: var.$gray;
  overflow: hidden;
  transform: rotate(-90deg);
}

.pieSegment1 {
  position: absolute;
  width: 50%;
  height: 50%;
  background: rgba(var.$danger, 0.7);
  transform-origin: 100% 100%;
}

.pieSegment2 {
  position: absolute;
  width: 50%;
  height: 50%;
  background: var.$black-50;
  transform-origin: 0 100%;
  left: 50%;
}

.pieSegment3 {
  position: absolute;
  width: 100%;
  height: 50%;
  background: var.$gray;
  bottom: 0;
}

.lineChart {
  height: 150px;
  min-width: 300px;
  background: var.$gray;
  position: relative;
  padding: 12px;
  border-radius: 10px;

  @include mix.vp-767 {
    width: 100%;
    height: 140px;
    min-width: 100%;
  }
}

.chartRow {
  display: flex;
  align-items: center;
  gap: 24px;
}

.skeletons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.skeletons.bottom {
  @include mix.vp-767 {
    display: none;
  }
}

.graph {
  width: 100%;
  height: 100%;
}

.curve1,
.curve2,
.curve3 {
  fill: none;
  stroke: var.$gray-dark;
  stroke-width: 2;
}

.curve2 {
  opacity: 0.7;
  stroke: var.$white;
}

.curve3 {
  opacity: 0.5;
  stroke: var.$danger;
  transform: translateX(5px);
}
