@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.image {
  cursor: pointer;
  width: 64px;
  height: 64px;
}

.text {
  @include mix.text(32, 700, 32);
  line-height: 100%;
  color: var.$black;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  height: 64px;
}

.light {
  color: var.$white;
}
