@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.content {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: var.$gray;
  padding: 76px 0 100px;
  transform: translateX(-100%);
  transition: all 0.3s;
  overflow: auto;
}

.opened {
  transform: translateX(0%);
}
