@use 'src/styles/mixins' as mix;

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
}

.adaptive {
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  overflow: hidden;

  @include mix.vp-1024 {
    padding-top: 68px;
  }
}

.main {
  padding-top: 0;

  @include mix.vp-1024 {
    padding-top: 0;
  }
}
