@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  @include mix.text(16, 400, 16);
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  @include mix.vp-1024 {
    @include mix.text(20, 400, 20);
  }
}

.icon {
  width: 20px;
  height: 20px;
}

.danger {
  color: var.$danger;
}
