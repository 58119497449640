@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  position: relative;
  z-index: 0;
  margin-bottom: 150px;

  @include mix.vp-1024 {
    margin-bottom: 80px;
  }
}

.content {
  width: 100%;
  display: flex;
  background-color: var.$gray-dark;
  padding: 150px 32px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;

  @include mix.vp-1024 {
    padding: 16px;
    background-color: var.$gray;
  }
}

.numbers {
  display: flex;
  gap: 20px;
  max-width: 1024px;
  width: 100%;

  @include mix.vp-1024 {
    flex-direction: column;
    width: 100%;
  }
}

.card {
  width: 100%;
  background-color: var.$white;
  padding: 32px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include mix.hover-interaction;
  cursor: default;

  @include mix.vp-1024 {
    width: 100%;
  }
}

.cardNumber {
  @include mix.text(48, 900, 48);
  color: var.$dark;
  margin-bottom: 12px;
}

.cardText {
  @include mix.text(24, 400, 24);
  color: var.$gray-black;
}

.cardIcon {
  width: 50px;
  height: 50px;
  background-color: var.$danger;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  z-index: 0;
}

.title {
  @include mix.text(40, 800, 40);
  text-align: center;
  color: var.$black;
  display: none;
}

.welcome {
  width: 100%;
  height: 100%;
  background-color: var.$white;
  border-radius: 10px;
  max-width: 1024px;
  height: 400px;
  padding: 32px;
  display: flex;
  gap: 60px;

  @include mix.vp-1024 {
    flex-direction: column;
    height: auto;
    gap: 32px;
  }

  &Left {
    min-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mix.vp-767 {
      min-width: 100%;
    }
  }

  &TitleSpan {
    color: var.$danger;
  }

  &Right {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
  }

  &Title {
    @include mix.text(32, 800, 36);
    color: var.$black;
  }

  &Text {
    @include mix.text(18, 400, 24);
    color: var.$dark;
  }

  &TextWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &Image {
    min-width: 200px;
    height: 200px;
    background-color: var.$gray;
    border-radius: 10px;

    @include mix.vp-767 {
      width: 100%;
    }
  }

  &Top {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  &Items {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 12px;
    width: 100%;

    @include mix.vp-767 {
      display: none;
    }
  }

  &Item {
    width: 100%;
    height: 30px;
    background-color: var.$gray;
    border-radius: 10px;
  }

  &Bottom {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.buttonDesktop {
  @include mix.vp-1024 {
    display: none;
  }
}

.buttonMobile {
  display: none;

  @include mix.vp-1024 {
    display: flex;
  }
}
