@use 'src/styles/mixins' as mix;
@use 'src/styles/variables' as var;

.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 24px;
  max-width: 620px;
  margin: 0 auto;
  gap: 24px;
}

.sign {
  color: var.$black;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mix.text(32, 700, 32);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.title {
  @include mix.text(24, 700, 24);
}

.description {
  @include mix.text(16, 400, 16);
}
