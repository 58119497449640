@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.main {
  @include mix.about-block;

  @include mix.vp-1024 {
    justify-content: space-between;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.qr {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;

  @include mix.vp-1024 {
    width: 120px;
    height: 120px;
  }
}

.footer {
  margin: 0 auto;
}
