@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.input {
  outline: none;
  border: none;
  color: var.$black;
  width: 100%;
  background-color: transparent;
  resize: none;
  padding: 0;
  display: block;
  box-sizing: border-box;
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    outline: none;
    border: none;
  }

  &:focus {
    outline: none;
    border: none;
  }

  &::placeholder {
    color: var.$black-50;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.container {
  position: relative;
  width: 100%;
  transition: all 0.3s ease;
}

.errorTag {
  color: var.$danger;
  @include mix.text(12, 400, 16);
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
