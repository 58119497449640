@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.skeleton {
  background: var.$gray;
  border-radius: 10px;
  padding: 16px;

  &.short {
    width: 40%;
  }

  &.medium {
    width: 65%;
  }

  &.long {
    width: 85%;
  }

  &.full {
    width: 100%;
  }
}
